import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
// import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import classes from "./EditCategory.module.css";

function EditCategory(props) {
  const [bookNumber, setBookNumber] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [coverImg, setCoverImg] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [completePdf, setCompletePdf] = useState(null);
  const [index, setIndex] = useState("");
  const [noteToReader, setNoteToReader] = useState(null);
  const [contentTable, setContentTable] = useState(null);
  const [reviews, setReviews] = useState(null);

  const [bookData, setBookData] = useState({});
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch the existing book data

    axios
      .get(`${BASE_URL}/books/${props.bookId}`)
      .then((response) => {
        const book = response.data;
        setBookData(book);
        console.log(response.data);
        setBookNumber(book.bookNumber);
        setTitle(book.title);
        setPrice(book.price);
        setStatus(book.status);
        setArrivalDate(book.arrivalDate);
        // Pre-fill the file input fields with the existing filenames
        setCoverImg(book.coverImg);
        setPreviewPdf(book.previewPdf);
        setCompletePdf(book.completePdf);
        setIndex(book.index);
        setReviews(book.reviews);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching book data:", error);
      });
  }, [props.bookId]);

  function handleFileChange(event, setStateFunction) {
    // Handle file selection for the new files
    const selectedFile = event.target.files[0];
    setStateFunction(selectedFile);
  }

  function handleBookNumberChange(e) {
    setBookNumber(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handlePriceChange(e) {
    setPrice(e.target.value);
  }

  function handleStatusChange(e) {
    setStatus(e.target.value);
  }

  function handleDateChange(e) {
    setArrivalDate(e.target.value);
  }

  // FOR ADMIN TO EDIT A BOOK
  function formSubmitHandler(e) {
    e.preventDefault();
    // setIsLoading(true);
    // Create form data for the updated book data
    const formData = new FormData();
    formData.append("bookNumber", bookNumber);
    formData.append("title", title);
    formData.append("price", price);
    formData.append("status", status);
    formData.append("arrivalDate", arrivalDate);
    formData.append("coverImg", coverImg);
    formData.append("previewPdf", previewPdf);
    formData.append("completePdf", completePdf);
    formData.append("index", index);
    formData.append("noteToReader", noteToReader);
    formData.append("contentTable", contentTable);
    formData.append("reviews", reviews);

    // Make the PUT request to update the book
    axios
      .post(`${BASE_URL}/admin/books/edit/${props.bookId}`, formData, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Book updated successfully:", response.data);
        // setIsLoading(false);
        setBookData(response.data.book);
        setTimeout(() => {
          props.onClose();
        }, 200);
        toast.success("Book updated successfully!");
      })
      .catch((error) => {
        toast.error("Error updating book!");
        // alert("Error updating book!");
        console.log("Error updating book:", error);
        // setIsLoading(false);
      });

    console.log("Book Updated!");
  }

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <>
      <div className={classes.editCategoryForm}>
        <div className={classes["editCategoryForm-Header"]}>
          <h3>Edit Category</h3>
          <CloseIcon className={classes.closeIcon} onClick={onCloseHandler} />
        </div>
        <hr />
        <div className={classes["editCategoryForm-content"]}>
          <h3>Category's Information</h3>

          <form onSubmit={formSubmitHandler}>
            <div className={classes.formInput}>
              <label htmlFor="bookNumber">Enter Book Number *</label>
              <input
                className={classes.categoryInput}
                id="bookNumber"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                name="bookNumber"
                value={bookNumber}
                required
                onChange={handleBookNumberChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="title">Enter Book Title *</label>
              <input
                className={classes.categoryInput}
                type="text"
                value={title}
                name="title"
                id="title"
                required
                onChange={handleTitleChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="price">Enter Book Price *</label>
              <input
                className={`${classes.categoryInput} ${classes.priceInput}`}
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                value={price}
                name="price"
                id="price"
                required
                onChange={handlePriceChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="status">Set Book Availability *</label>
              <div className={classes.statusDiv}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="statusYes"
                    value="yes"
                    checked={status === "yes"}
                    onChange={handleStatusChange}
                  />
                  <label className="form-check-label" htmlFor="statusYes">
                    Available
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="statusNo"
                    value="no"
                    checked={status === "no"}
                    onChange={handleStatusChange}
                  />
                  <label className="form-check-label" htmlFor="statusNo">
                    Unavailable
                  </label>
                </div>
              </div>
            </div>

            {status === "no" && (
              <div className={classes.formInput}>
                <label htmlFor="arrivalDate">
                  Expected next availability *
                </label>
                <input
                  style={{ width: "65%" }}
                  className={classes.categoryInput}
                  type="text"
                  value={arrivalDate}
                  name="arrivalDate"
                  id="arrivalDate"
                  placeholder="Eg: 'Coming On 1st October 2023'"
                  onChange={handleDateChange}
                />
              </div>
            )}

            <div className={classes.formInput}>
              <label htmlFor="coverImg">Upload Book Cover Image *</label>
              <input
                type="file"
                accept="image/*"
                name="coverImg"
                id="coverImg"
                onChange={(event) => handleFileChange(event, setCoverImg)}
                // value={coverImg}
                // required
              />
              {/* {bookData.coverImg && <p>Existing file: {bookData.coverImg}</p>} */}
            </div>
            <div className={classes.formInput}>
              <label htmlFor="previewPdf">Upload Book Preview PDF *</label>
              <input
                type="file"
                accept="application/pdf"
                name="previewPdf"
                id="previewPdf"
                onChange={(event) => handleFileChange(event, setPreviewPdf)}
                // value={previewPdf}
                // required
              />
              {/* {bookData.previewPdf && (
                <p>Existing file: {bookData.previewPdf}</p>
              )} */}
            </div>
            <div className={classes.formInput}>
              <label htmlFor="completePdf">Upload Book Complete PDF *</label>
              <input
                type="file"
                accept="application/pdf"
                name="completePdf"
                id="completePdf"
                onChange={(event) => handleFileChange(event, setCompletePdf)}
                // value={completePdf}
                // required
              />
              {/* {bookData.completePdf && (
                <p>Existing file: {bookData.completePdf}</p>
              )} */}
            </div>
            <div className={classes.formInput}>
              <label htmlFor="noteToReader">Upload Note to Reader PDF*</label>
              <input
                type="file"
                accept="application/pdf"
                name="noteToReader"
                id="noteToReader"
                onChange={(event) => handleFileChange(event, setNoteToReader)}
                // value={noteToReader}
                // required
              />
              {/* {bookData.noteToReader && (
                <p>Existing file: {bookData.noteToReader}</p>
              )} */}
            </div>
            <div className={classes.formInput}>
              <label htmlFor="contentTable">Upload Content Table PDF*</label>
              <input
                type="file"
                accept="application/pdf"
                name="contentTable"
                id="contentTable"
                onChange={(event) => handleFileChange(event, setContentTable)}
                // value={contentTable}
                // required
              />
              {/* {bookData.contentTable && (
                <p>Existing file: {bookData.contentTable}</p>
              )} */}
            </div>
            <div className={classes.indexDiv}>
              <label htmlFor="index">Upload Index *</label>
              <CKEditor
                name="index"
                editor={ClassicEditor}
                data={index}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setIndex(data);
                  console.log({ event, editor, data });
                }}
              />
            </div>

            <div className={classes.formControl}>
              <button onClick={onCloseHandler}> Cancel</button>
              <button type="submit"> Submit</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default EditCategory;
