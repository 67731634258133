import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";

import { ToastContainer, toast } from "react-toastify";

import Header from "../../components/Header";
import classes from "./UserProfile.module.css";

import Sidebar from "../../components/Sidebar";
import { CircularProgress } from "@mui/material";

function UserProfile() {
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    mobile: "",
    email: "",
    fName: "",
    lName: "",
    city: "",
    country: "",
  });

  const { mobile, email, fName, lName, city, country } = userDetails;

  // GETTING USER DETAILS
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user/details`, {
        withCredentials: true,
      }) //set unique route with user id in url to get specific books for each user in reading space
      .then((res) => {
        // setAvlBooks(res.data);
        console.log(res.data);
        const userData = res.data;
        setIsLoading(false);
        setUserDetails({
          mobile: userData.mobile,
          email: userData.email,
          fName: userData.fName,
          lName: userData.lName,
          city: userData.city,
          country: userData.country,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Header />
      <hr />
      <div className={classes.wrapper}>
        <Sidebar />
        {isLoading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.rightColumn}>
            <div className={classes.gridContainer}>
              <div className={classes.gridItem}>
                <h5>Name:</h5>
              </div>
              <div className={classes.gridItem}>
                <p>{`${fName} ${lName}`}</p>
              </div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.gridItem}>
                <h5>Email:</h5>
              </div>
              <div className={classes.gridItem}>
                <p>{email}</p>
              </div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.gridItem}>
                <h5>Mobile:</h5>
              </div>
              <div className={classes.gridItem}>
                <p>{mobile}</p>
              </div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.gridItem}>
                <h5>City:</h5>
              </div>
              <div className={classes.gridItem}>
                <p>{city}</p>
              </div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.gridItem}>
                <h5>Country:</h5>
              </div>
              <div className={classes.gridItem}>
                <p>{country}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
