import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import classes from "./UserPassword.module.css";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

function UserPassword() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);

    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }

    try {
      // Send a request to the backend to change the password
      const response = await axios.post(
        `${BASE_URL}/user/password`,
        {
          oldPassword,
          newPassword,
          confirmPassword,
        },
        { withCredentials: true }
      );
      console.log(response.data.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setLoading(false);
      setSuccess(true);
      toast.success("Password changed successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccess(false);
      toast.error("Password change failed!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <div className={classes.mainContainer}>
      <Header />
      <hr />
      <div className={classes.wrapper}>
        <Sidebar />
        <div className={classes.rightColumn}>
          <form onSubmit={changePasswordHandler}>
            <div className={classes.inputDiv}>
              <label htmlFor="oldPassword">Enter Current Password *</label>
              <input
                type="password"
                name="oldPassword"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className={classes.inputDiv}>
              <label htmlFor="newPassword">Enter New Password *</label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>

            <div className={classes.inputDiv}>
              <label htmlFor="confirmPassword">Confirm New Password *</label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {/* <p style={{ color: "grey" }}>{message}</p> */}

            <div className={classes.submitBtn}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={buttonSx}
                    disabled={loading}
                    type="submit"
                  >
                    Save
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UserPassword;
