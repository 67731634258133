import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import classes from "./Password.module.css";
import Sidebar from "../components/Sidebar";

function Password() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [message, setMessage] = useState("");

  // to change admin password
  async function changePasswordHandler(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!");
      // setMessage("Passwords don't match!");
    }

    try {
      // Send a request to the backend to change the password
      const response = await axios.post(
        `${BASE_URL}/admin/password`,
        {
          oldPassword,
          newPassword,
          confirmPassword,
        },
        { withCredentials: true }
      );
      toast.success("Password changed successfully!");
      // setMessage(response.data.message);
      console.log(response.data.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("Error changing password!");
      console.error(error);
      // setMessage("Error changing password");
    }
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar />

      <div className={classes.password}>
        <Header />
        <div className={classes["password-container"]}>
          <h2>
            <strong>Change Password</strong>
          </h2>
          <form onSubmit={changePasswordHandler}>
            <div className={classes.formInput}>
              <label htmlFor="oldPassword">Enter Current Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="oldPassword"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="newPassword">Enter New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="confirmPassword">Confirm New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {/* <p style={{ color: "grey" }}>{message}</p> */}

            <div className={classes.passwordBottomBar}>
              <button type="submit">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Password;
