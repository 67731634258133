import React from "react";
import classes from "./Table.module.css";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ViewCategory from "./ViewCategory";
import EditCategory from "./EditCategory";
import ReviewsModal from "./ReviewsModal";

function Table(props) {
  const [reviewsModalActive, setReviewsModalOpen] = useState(false);
  const [editFormActive, setEditFormActive] = useState(false);
  const [viewFormActive, setViewFormActive] = useState(false);
  const [activeBookId, setActiveBookId] = useState(null);

  function openReviewsModal(bookId) {
    setActiveBookId(bookId);
    setReviewsModalOpen(true);
  }

  function closeReviewsModal() {
    setReviewsModalOpen(false);
  }

  function openEditFormHandler(bookId) {
    setActiveBookId(bookId);
    setEditFormActive(true);
  }

  function openViewFormHandler(bookId) {
    setActiveBookId(bookId);
    setViewFormActive(true);
    console.log("open");
  }

  function closeEditFormHandler() {
    setEditFormActive(false);
  }

  function closeViewFormHandler() {
    setViewFormActive(false);
  }

  return (
    <>
      <div className={classes.table}>
        <table className={classes["content-table"]}>
          <tbody>
            <tr>
              <th>Book Number</th>
              <th>Book Name</th>
              <th>Book Price</th>
              <th>Book Reviews</th>
              <th>Availability</th>
              <th>Actions</th>
            </tr>
            {props.books.map((book) => {
              return (
                <tr key={book._id}>
                  <td>{book.bookNumber}</td>
                  <td>{book.title}</td>
                  <td>{book.price}</td>
                  <td>
                    <VisibilityIcon
                      fontSize="small"
                      onClick={() => openReviewsModal(book._id)}
                    />
                  </td>
                  <td>{book.status === "yes" ? "Yes" : "No"}</td>
                  <td>
                    <div className={classes.action}>
                      <EditIcon
                        fontSize="small"
                        onClick={() => openEditFormHandler(book._id)}
                      />
                      <VisibilityIcon
                        fontSize="small"
                        onClick={() => openViewFormHandler(book._id)}
                      />
                      <DeleteIcon
                        fontSize="small"
                        onClick={() => props.onDelete(book._id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {editFormActive && (
        <EditCategory bookId={activeBookId} onClose={closeEditFormHandler} />
      )}
      {viewFormActive && (
        <ViewCategory bookId={activeBookId} onClose={closeViewFormHandler} />
      )}

      {reviewsModalActive && (
        <ReviewsModal
          bookId={activeBookId}
          open={reviewsModalActive}
          onClose={closeReviewsModal}
        />
      )}
    </>
  );
}

export default Table;
