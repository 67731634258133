import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Payment() {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  async function handlePaymentHandler(e) {
    e.preventDefault();
    // console.log(amount);
    try {
      const response = await axios.post(
        `${BASE_URL}/instamojo/pay`,
        { amount },
        { withCredentials: true }
      );
      const paymentUrl = response.data;
      console.log(response.data);

      // Redirect the user to the Instamojo payment page
      window.location.href = paymentUrl;

    } catch (error) {
      //   toast.error("Error Initiating Payment!", {
      //     position: "top-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: false,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      //   console.error("Error initiating payment:", error);
      //   // Handle error scenario
    }
  }

  return (
    <div
      className="paymentContainer"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form onSubmit={handlePaymentHandler}>
        <label htmlFor="amount" />
        <input
          value={amount}
          type="number"
          name="amount"
          id="amount"
          onChange={(e) => setAmount(e.target.value)}
        />
        <button
          className="btn btn-primary"
          style={{ width: "15rem", padding: "10px" }}
        >
          Proceed to Payment
        </button>
      </form>
    </div>
  );
}

export default Payment;
