import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";

import classes from "./CustomerBooksPopUp.module.css";
import CloseIcon from "@mui/icons-material/Close";

function CustomerBooksPopUp(props) {
  const [userBooks, setUserBooks] = useState([]);
  const [hasBooks, setHasBooks] = useState(true);

  // to GET books purchased by a specific user and display the list in pop-up
  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/${props.userId}/books`, {
        withCredentials: true,
      }) //set unique route with user id in url to get specific books for each user in reading space
      .then((res) => {
        // setAvlBooks(res.data);
        const purchasedBooks = res.data;
        setUserBooks(purchasedBooks);
        if (purchasedBooks.length > 0) {
          setHasBooks(true);
        } else {
          setHasBooks(false);
        }
        console.log(purchasedBooks);
      })
      .catch((err) => {
        console.log("Error fetching books:" + err);
      });
  }, [props.userId]);

  function closePopUpHandler() {
    props.onClose();
  }

  const booksPurchasedList = userBooks.map((book) => (
    <>
      <div key={book._id} className={classes["PopUp-content"]}>
        <div className={classes["PopUp-content-row"]}>
          <h4>Book Number:</h4>
          <p>{book.bookNumber}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>Book Name:</h4>
          <p>{book.title}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>Price:</h4>
          <p>{book.price}/-</p>
        </div>
      </div>
    </>
  ));

  return (
    <>
      <div className={classes.bookPurchasedPopUp}>
        <div className={classes["PopUp-header"]}>
          <h3>Books Purchased</h3>
          <CloseIcon
            className={classes.closeIcon}
            onClick={closePopUpHandler}
          />
        </div>
        {!hasBooks ? (
          <div className={classes.noBooksContent}>
            <h6>No Books purchased yet!</h6>
          </div>
        ) : (
          booksPurchasedList
        )}
      </div>
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default CustomerBooksPopUp;
