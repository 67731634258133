import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { ThumbnailDirection } from "@react-pdf-viewer/thumbnail";
import { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface PdfViewerProps {
  pdf: string;
  onClose: () => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdf, onClose }) => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [showThumbnails, setShowThumbnails] = useState(false);
  console.log("url", pdf);

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  return (
    <div
      className="rpv-core__viewer"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        className="pdfToolbar" //style in index.css
        style={{
          alignItems: "center",
          backgroundColor: "darkGrey",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          position: "fixed",
          display: "flex",
          margin: "auto",
          top: "5px",
          left: "5%",
          zIndex: 1000,
          width: "85%",
          borderRadius: "10px",
          padding: "0.25rem",
        }}
      >
        <Toolbar>
          {(props: ToolbarSlot) => {
            const { EnterFullScreen, Zoom, ZoomIn, ZoomOut } = props;
            return (
              <>
                <div style={{ padding: "0px 2px", marginRight: "auto" }}>
                  <EnterFullScreen />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Zoom />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>

                <div
                  style={{
                    padding: "0px 2px",
                    marginLeft: "auto",
                    marginRight: "8px",
                  }}
                >
                  <h6
                    onClick={onClose}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: "pointer",
                      padding: "0px 20px",
                      marginBottom: "0",
                    }}
                  >
                    Go Back
                  </h6>
                </div>
              </>
            );
          }}
        </Toolbar>
      </div>
      <div style={{ display: "flex" }}>
        {/* show thumbnails button */}
        {!showThumbnails && (
          <button
            onClick={() => setShowThumbnails((prev) => !prev)}
            style={{
              borderStyle: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
              color: "black",
              fontWeight: "bold",
              position: "fixed",
              top: "50%",
              left: "15px",
              fontSize: "1.1rem",
              zIndex: 99999,
            }}
          >
            {/* Thumbnails */}
            <ArrowForwardIosIcon />{" "}
          </button>
        )}

        {showThumbnails && (
          <div
            // className="pdfThumbnailsSidebar"
            style={{
              // borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              backgroundColor: "rgba(70, 70, 70, 0.689)",
              overflow: "auto",
              width: "25%",
              zIndex: 999,
              position: "fixed",
              height: "100%",
              color: "white",
            }}
          >
            <Thumbnails thumbnailDirection={ThumbnailDirection.Vertical} />
          </div>
        )}

        <div
          onClick={() => setShowThumbnails(false)}
          style={{
            // minHeight: "100vh", //to fix page fit issue. earlier it kept zooming out until it disappeared. now with this it stops minimizing once the page reaches 100vh
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
            <div
              style={{
                //this wrapping div around Viewer is most important for thumbnail navigation and special zoom levels(page-fit, actual-width, page-width to work)
                height: "100vh",
                width: "100vw",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Viewer
                // fileUrl={URL.createObjectURL(pdf)} //DON'T DO THIS HERE, CREATES INFINITE RENDERING OF VIEWER COMPONENT
                fileUrl={pdf} //DIRECTLY PASS THE URL OBJECT
                // fileUrl={"http://localhost:3000/pdf-open-parameters.pdf"}
                plugins={[toolbarPluginInstance, thumbnailPluginInstance]}
                // plugins={[defaultLayoutPluginInstance]}
              />
            </div>
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
