import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import classes from "./EditProfile.module.css";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

function EditProfile() {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    mobile: "",
    email: "",
    fName: "",
    lName: "",
    city: "",
    country: "",
  });

  const { mobile, email, fName, lName, city, country } = userDetails;

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  // GETTING USER DETAILS
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user/details`, {
        withCredentials: true,
      }) //set unique route with user id in url to get specific books for each user in reading space
      .then((res) => {
        console.log(res.data);
        const userData = res.data;
        setIsLoadingData(false);
        setUserDetails({
          mobile: userData.mobile,
          email: userData.email,
          fName: userData.fName,
          lName: userData.lName,
          city: userData.city,
          country: userData.country,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingData(false);
      });
  }, []);

  function changeHandler(e) {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  }

  // POST EDITS IN USER DETAILS
  function submitChangesHandler(e) {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    axios
      .post(`${BASE_URL}/user/edit`, userDetails, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("User updated successfully:", response.data);
        console.log(response.data);
        setLoading(false);
        setSuccess(true);
        toast.success("Details updated!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        // setTimeout(() => {
        //   navigate("/myprofile");
        // }, 500);
      })
      .catch((error) => {
        console.log("Error updating user:", error);
        setLoading(false);
        setSuccess(false);
        toast.error("An error occurred. Try Again!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        // alert("Error editing details!");
      });
  }

  return (
    <div className={classes.mainContainer}>
      <Header />
      <hr />
      <div className={classes.wrapper}>
        <Sidebar />
        {isLoadingData ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.rightColumn}>
            <form onSubmit={submitChangesHandler}>
              <div className={classes.inputDiv}>
                <label htmlFor="fName">First Name *</label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  value={fName}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className={classes.inputDiv}>
                <label htmlFor="lName">Last Name *</label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  value={lName}
                  onChange={changeHandler}
                  required
                />
              </div>

              <div className={classes.inputDiv}>
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className={classes.inputDiv}>
                <label htmlFor="mobile">Mobile *</label>
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobile"
                  id="mobile"
                  value={mobile}
                  onChange={changeHandler}
                  required
                />
              </div>

              <div className={classes.inputDiv}>
                <label htmlFor="city">City *</label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={city}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className={classes.inputDiv}>
                <label htmlFor="country">Country *</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  value={country}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className={classes.submitBtn}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      disabled={loading}
                      type="submit"
                    >
                      Save
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </div>
            </form>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditProfile;
