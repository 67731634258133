import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "../../pages/Welcome.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function SignIn(props) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });

  const [isVerified, setisVerified] = useState(true);

  const { email, password } = inputValue;

  function handleOnChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  async function signinHandler(e) {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/login`,
        {
          ...inputValue,
        },
        { withCredentials: true }
      );
      console.log(data);
      const { success, message, state } = data;
      if (state === "PENDING") {
        setisVerified(false);
      }
      if (success) {
        setisVerified(true);
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/readingspace");
        }, 1500);
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setInputValue({
      ...inputValue,
      email: "",
      password: "",
    });
  }

  return (
    <>
      <ToastContainer />
      {/* BASIC SIGN IN FORM */}
      <form onSubmit={signinHandler}>
        <h4>Access Books</h4>
        <input
          onChange={handleOnChange}
          value={email}
          type="email"
          name="email"
          placeholder="Email Id"
        />
        <input
          onChange={handleOnChange}
          value={password}
          type="password"
          name="password"
          placeholder="Password"
        />
        <div className={classes.forgotPwd} style={{ textAlign: "right" }}>
          <p onClick={() => props.onForgot()}>Forgot Password?</p>
        </div>
        {!isVerified && (
          <p style={{ color: "red" }}>
            Please{" "}
            <Link
              to="/request-otp"
              style={{ color: "red", textDecoration: "underline" }}
            >
              verify your email
            </Link>{" "}
            to login!
          </p>
        )}

        <div className={classes.formActions}>
          {isVerified && (
            <div className={classes.logIn}>
              <button type="submit">
                Sign In <ArrowForwardIcon fontSize="small" />
              </button>
            </div>
          )}

          <div className={classes.signUp}>
            <Link to="/signup">Don't have an account? Sign Up</Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default SignIn;
