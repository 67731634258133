import React from "react";
import classes from "./ContactUsModal.module.css";
import gmailLogo from "../assets/logos/gmail.png";
import PlaceIcon from "@mui/icons-material/Place";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  // color: "white",
  // width: 400,
  width: "50%",
  // width: "450px" /* Default width for all screen sizes */,
  "@media (max-width: 768px)": {
    width: "85%" /* Width for screens up to 768px */,
  },
  "@media (max-width: 480px)": {
    width: "95%" /* Width for screens up to 480px */,
  },
  bgcolor: "background.paper",
  // bgcolor: "black",
  border: "1px solid red",
  boxShadow: 24,
  p: 3,
};

function ContactUsModal(props) {
  function handleClose() {
    props.onClose();
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <h1 className={classes.contactHeader}>Contact Us</h1>
            <hr
              style={{
                width: "15%",
                borderTop: "3px solid red",
                marginTop: "0",
                marginBottom: "25px",
              }}
            />
            <p className={classes.contactContent}>
              For any questions, feedback, or inquiries, please reach out to us
              at:
            </p>
            <div className={classes.emailDiv}>
              {/* <img src={gmailLogo} alt="gmailLogo" /> */}
              <MailOutlineIcon fontSize="medium" />
              <a href="mailto:support@whitepagesphysics.website">
                support@whitepagesphysics.website
              </a>
            </div>
            <div className={classes.addressDiv}>
              <PlaceIcon fontSize="medium" />
              <p>Yamuna Expressway, Greater Noida, UP-201301</p>
            </div>

            <p className={classes.contactContent}>
              We value your input and look forward to hearing from you.
            </p>

            {/* <Typography id="transition-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ContactUsModal;
