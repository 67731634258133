import React from "react";
import classes from "./Whitepages.module.css";
// import AnimatedPage from "../components/AnimatedPage";
import fullKashiLogo from "../assets/logos/brand-logo-light.jpg";
import kashiLogoMan from "../assets/logos/kashi-logo-man.png";
import kashiLogoText from "../assets/logos/kashi-logo-text.png";
import physicsImg from "../assets/logos/physics.png";
import naturalScienceImg from "../assets/logos/naturalScience-light.png";
import { Link } from "react-router-dom";
import ContactUsModal from "../components/ContactUsModal";
import contactIcon from "../assets/logos/envelope.png";
import GoToTop from "../components/GoToTop";

function Whitepages() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    // <AnimatedPage>
    <>
      <GoToTop />
      <div className={classes.wrapper}>
        <div className={classes.messageIcon}>
          <Link onClick={handleOpen}>
            <img src={contactIcon} alt="email-us-icon" />
          </Link>
        </div>
        <div className={classes.leftColumn}>
          <div className={classes.textLogoDiv}>
            <img
              className={classes.fullKashiLogoImg}
              src={fullKashiLogo}
              alt="kashi-logo"
            />
            <img
              className={classes.kashiLogoTextImg}
              src={kashiLogoText}
              alt="Kashi-logo-text"
            />
          </div>
          <div className={classes.bookNamesDiv}>
            <Link to="/">
              <img
                className={classes.physicsImg}
                src={physicsImg}
                alt="WhitePages-Physics-Img"
              />
            </Link>
            <Link to="#">
              <img
                className={classes.scienceImg}
                src={naturalScienceImg}
                alt="WhitePages-Natural-Sciences-Img"
              />
            </Link>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <img
            className={classes.kashiLogoManImg}
            src={kashiLogoMan}
            alt="Kashi-logo-man"
          />
        </div>

        {/* CONTACT US MODAL */}
      </div>
      <ContactUsModal open={open} onClose={handleClose} />
    </>
    // </AnimatedPage>
  );
}

export default Whitepages;
