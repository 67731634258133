import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../apiConfig";

import { useCookies } from "react-cookie";

function PrivateRoutesAdmin() {
  const [cookies] = useCookies(["tokenA"]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    // Check if the token exists in the cookie
    // if (cookies.tokenA) {
      // API call to validate the token on the server
      axios
        .post(`${BASE_URL}/validateAdminToken`, {}, { withCredentials: true })
        .then((response) => {
          if (response.data.valid) {
            setIsAuthenticated(true);
            console.log("trueeeeeeeeee");
            console.log(response.data.valid);
          } else {
            console.log("falseeeee");
            setIsAuthenticated(false);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log("falseeeeeeee");
          setIsAuthenticated(false);
        });
    // } else {
    //   console.log("falseeeeee");
    //   setIsAuthenticated(false);
    // }
  }, [cookies]);

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default PrivateRoutesAdmin;
