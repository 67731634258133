import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { Modal, Box } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import moment from "moment";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import classes from "./ReviewsModal.module.css";

function ReviewsModal(props) {
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${props.bookId}/reviews`)
      .then((res) => {
        // console.log(res.data);
        setReviews(res.data.reviews);
        setReviewsLoading(false);
        // console.log(book);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.bookId, reviews]);

  function reviewDeleteHandler(id) {
    axios
      .post(`${BASE_URL}/admin/reviews/${id}`, null, {
        withCredentials: true,
      })
      .then(() => {
        setReviews((prevReviews) => {
          return prevReviews.filter((review) => {
            return review.id !== id;
          });
        });
        toast.success("Review deleted!");
      })
      .catch((error) => {
        toast.error("Error deleting review!");
        console.log("Error deleting review:", error);
      });
  }

  // FUNCTION TO GET DATE (createdAt from review model) AND FORMAT IT FOR DISPLAYING
  const formatReviewDate = (createdAt) => {
    const now = moment(); // Current date and time
    const reviewDate = moment(createdAt);

    if (now.isSame(reviewDate, "day")) {
      // If it's the same day, show the time ago
      return reviewDate.fromNow();
    } else {
      // Otherwise, format as dd mm yyyy
      return reviewDate.format("DD MMM YYYY");
    }
  };

  return (
    <>
      <Modal open={props.open} onClose={() => props.onClose()}>
        <Fade in={props.open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              maxHeight: "500px",
              padding: "10px", //new
              overflow: "auto", //new
              bgcolor: "#FFF",
              // borderRadius: "4px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes.reviewsHeader}>
              <h4>Reader Reviews</h4>
            </div>

            {reviewsLoading ? (
              <div className={classes.loadingDiv}>
                <CircularProgress color="inherit" />
              </div>
            ) : reviews.length > 0 ? (
              reviews.map((review) => (
                <div className={classes.reviewWrapper} key={review.id}>
                  <Paper sx={{ marginBottom: "10px" }} elevation={3}>
                    <div className={classes.reviewCardContent}>
                      <div className={classes.rating}>
                        <div className={classes.nameWithDate}>
                          <p className={classes.name}>{review.user}</p>
                          <p className={classes.date}>
                            reviewed {formatReviewDate(review.createdAt)}
                          </p>
                          <Rating
                            name="rating"
                            size="small"
                            sx={{ marginLeft: "10px" }}
                            value={review.rating}
                            readOnly
                          />
                        </div>
                        <DeleteIcon
                          fontSize="small"
                          className={classes.deleteIcon}
                          onClick={() => reviewDeleteHandler(review.id)}
                        />
                      </div>
                      {review.comment && (
                        <p className={classes.comment}>{review.comment}</p>
                      )}
                    </div>
                  </Paper>
                </div>
              ))
            ) : (
              <div className={classes.noReviews}>
                <p>No reviews yet!</p>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default ReviewsModal;
