import classes from "./Header.module.css";

function Header() {
  return (
    <div className={classes.header}>
      <h4>
        Hello <strong>Admin</strong>
      </h4>
    </div>
  );
}

export default Header;
