import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import classes from "./Category.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import Table from "../components/Table";
import AddCategory from "../components/AddCategory";

function Category() {
  const [addCatForm, setAddCatForm] = useState(false);
  const [books, setBooks] = useState([]);

  // to GET all books and display them in table
  useEffect(() => {
    console.log(document.cookie);
    axios
      .get(`${BASE_URL}/books`)
      .then((res) => {
        //sort books based on bookNumber field
        const sortedBooks = res.data.sort(
          (a, b) => a.bookNumber - b.bookNumber
        );
        setBooks(sortedBooks);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function addCategoryHandler(newBook) {
    setBooks((prevBooks) => [...prevBooks, newBook]);
  }

  function formOpenHandler() {
    setAddCatForm(true);
  }

  function formCloseHandler() {
    setAddCatForm(false);
  }

  // TO DELETE A SPECIFIC BOOK
  function deleteBookHandler(bookId) {
    axios
      .post(`${BASE_URL}/admin/books/${bookId}`, {
        withCredentials: true,
      })
      .then(() => {
        setBooks((prevBooks) => {
          return prevBooks.filter((book) => {
            return book._id !== bookId;
          });
        });
        toast.success("Book deleted!");
      })
      .catch((error) => {
        toast.error("Error deleting book!");
        console.log("Error deleting book:", error);
      });
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.category}>
        <Header />
        <div className={classes.addCategory}>
          <h3>All Categories</h3>
          <button className={classes.addDiv} onClick={formOpenHandler}>
            <AddIcon className={classes.addIcon} />
            <h4>Add</h4>
          </button>
        </div>
        <hr />
        <Table onDelete={deleteBookHandler} books={books} />

        {addCatForm && (
          <AddCategory onAdd={addCategoryHandler} onClose={formCloseHandler} />
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Category;
