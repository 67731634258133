//  can make one header component for user logged out and logged in using useState
// this one is presently for logged in
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../apiConfig";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { motion } from "framer-motion";

import classes from "./Header.module.css";
import brandLogo from "../assets/logos/brand-logo-light.jpg";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [cookies] = useCookies([]);
  const navigate = useNavigate();

  const animations = {
    initial: { x: 100 },
    animate: { x: 0 },
    exit: { x: -100 },
  };

  useEffect(() => {
    // Check if the token exists in the cookie
    // if (cookies.token) {
    // API call to validate the token on the server
    axios
      .post(`${BASE_URL}/validateToken`, {}, { withCredentials: true })
      .then((response) => {
        if (response.data.valid) {
          setLoggedIn(true);
          console.log(response.data.valid);
        } else {
          setLoggedIn(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoggedIn(false);
      });
    // } else {
    //   setLoggedIn(false);
    // }
  }, [cookies]);

  function openNavHandler() {
    setOpenNav(true);
  }

  function closeNavHandler() {
    setOpenNav(false);
  }

  const logoutHandler = () => {
    // Send a request to the server to log out the user
    axios
      .post(`${BASE_URL}/user/logout`, null, {
        withCredentials: true,
      })
      .then((response) => {
        // Check if the logout was successful
        if (response.status === 200) {
          // Redirect the user to the login page or perform any other action
          // window.location.href = "/welcome";
          navigate("/welcome"); //CHANGED THIS
        } else {
          // Handle logout error
          console.error("Logout failed.");
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error during logout:", error);
      });
  };

  const hamburgerMenuBtn = (
    <span className={classes.menuBtn} onClick={openNavHandler}>
      &#9776;
    </span>
  );

  const responsiveSideNavbar = (
    <motion.div
      className={
        openNav
          ? `${classes.sideNav} ${classes.openSideNav}`
          : `${classes.sideNav}`
      }
      id="sideNav"
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.6 }}
    >
      <Link to="#" className={classes.closeBtn} onClick={closeNavHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </Link>

      <h4>
        <Link to="/explorebooks">Explore Books</Link>
      </h4>

      <h4>
        <Link to="/readingspace">Reading Space</Link>
      </h4>

      {isLoggedIn ? (
        <>
          <h4>
            <Link to="/myprofile">My Profile</Link>
          </h4>
          <h4>
            <Link onClick={logoutHandler}>
              Sign Out <LogoutIcon fontSize="small" />
            </Link>
          </h4>
        </>
      ) : (
        <h4>
          <Link to="/welcome">Sign In/Sign Up</Link>
        </h4>
      )}
    </motion.div>
  );

  return (
    <nav>
      <div className={classes.headerLogo}>
        <Link to="https://kashispace.live/">
          <img src={brandLogo} alt="bookmans-logo" />
        </Link>
      </div>
      {!openNav ? hamburgerMenuBtn : responsiveSideNavbar}

      <div className={classes.navLinks}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to="/explorebooks"
            >
              Explore Books
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to="/readingspace"
            >
              My Reading Space
            </NavLink>
          </li>
          <li>
            {isLoggedIn ? (
              <>
                <div className={classes.dropdown}>
                  <button className={classes.dropbtn}>
                    <Link to="#">
                      <PersonIcon />
                      <ArrowDropDownIcon />
                    </Link>
                  </button>
                  <div className={classes.dropdownContent}>
                    <ul>
                      <li>
                        <Link to="/myprofile">My Profile</Link>
                      </li>
                      <li>
                        <Link onClick={logoutHandler}>Sign Out</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <Link className={classes.logout} to="/welcome">
                Sign In/Sign Up
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
