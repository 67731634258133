import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import classes from "./AddCategory.module.css";
import CloseIcon from "@mui/icons-material/Close";

function AddCategory(props) {
  const [bookNumber, setBookNumber] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [coverImg, setCoverImg] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [completePdf, setCompletePdf] = useState(null);
  const [index, setIndex] = useState("");
  const [noteToReader, setNoteToReader] = useState(null);
  const [contentTable, setContentTable] = useState(null);
  // const [reviews, setReviews] = useState(null);

  function handleBookNumberChange(e) {
    setBookNumber(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handlePriceChange(e) {
    setPrice(e.target.value);
  }

  function handleStatusChange(e) {
    setStatus(e.target.value);
  }

  function handleDateChange(e) {
    setArrivalDate(e.target.value);
  }

  function handleCoverChange(e) {
    setCoverImg(e.target.files[0]);
  }

  function handlePreviewChange(e) {
    setPreviewPdf(e.target.files[0]);
  }

  function handleCompletePdfChange(e) {
    setCompletePdf(e.target.files[0]);
  }

  function handleNoteChange(e) {
    setNoteToReader(e.target.files[0]);
  }

  function handleContentTableChange(e) {
    setContentTable(e.target.files[0]);
  }

  // function handleReviewsChange(e) {
  //   setReviews(e.target.files[0]);
  // }

  // FOR ADMIN TO ADD A NEW BOOK
  async function formSubmitHandler(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bookNumber", bookNumber);
    formData.append("title", title);
    formData.append("price", price);
    formData.append("arrivalDate", arrivalDate);
    formData.append("status", status);
    formData.append("coverImg", coverImg);
    formData.append("previewPdf", previewPdf);
    formData.append("completePdf", completePdf);
    formData.append("index", index);
    formData.append("noteToReader", noteToReader);
    formData.append("contentTable", contentTable);
    // formData.append("reviews", reviews);

    console.log(bookNumber);

    try {
      await axios.post(`${BASE_URL}/admin/books/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      props.onClose();
      // props.onAdd({
      //   bookNumber,
      //   title,
      //   price,
      //   status,
      //   coverImg,
      //   previewPdf,
      //   completePdf,
      //   index,
      //   noteToReader,
      //   contentTable,
      //   reviews,
      // });
      console.log(formData);
      console.log("Book data submitted successfully");
      toast.success("Book added!");
      console.log(bookNumber);
    } catch (error) {
      // alert("Error submitting book data!");
      toast.error("Error adding book!");
      console.error("Error submitting book data");
    }
  }

  function formCloseHandler() {
    props.onClose();
  }

  return (
    <>
      <div className={classes.addCategoryForm}>
        <div className={classes["addCategoryForm-Header"]}>
          <h3>Add Book</h3>
          <CloseIcon className={classes.closeIcon} onClick={formCloseHandler} />
        </div>
        <hr />
        <div className={classes["addCategoryForm-content"]}>
          <h3>Book's Information</h3>

          <form onSubmit={formSubmitHandler}>
            <div className={classes.formInput}>
              <label htmlFor="bookNumber">Enter Book Number *</label>
              <input
                className={classes.categoryInput}
                id="bookNumber"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                name="bookNumber"
                value={bookNumber}
                required
                onChange={handleBookNumberChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="title">Enter Book Title *</label>
              <input
                className={classes.categoryInput}
                type="text"
                value={title}
                name="title"
                id="title"
                required
                onChange={handleTitleChange}
              />
            </div>

            <div className={classes.formInput}>
              <label htmlFor="price">Enter Book Price *</label>
              <input
                className={`${classes.categoryInput} ${classes.priceInput}`}
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                value={price}
                name="price"
                id="price"
                required
                onChange={handlePriceChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="status">Set Book Availability *</label>
              <div className={classes.statusDiv}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="statusYes"
                    value="yes"
                    checked={status === "yes"}
                    onChange={handleStatusChange}
                  />
                  <label className="form-check-label" htmlFor="statusYes">
                    Available
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="statusNo"
                    value="no"
                    checked={status === "no"}
                    onChange={handleStatusChange}
                  />
                  <label className="form-check-label" htmlFor="statusNo">
                    Unavailable
                  </label>
                </div>
              </div>
            </div>

            {status === "no" && (
              <div className={classes.formInput}>
                <label htmlFor="arrivalDate">
                  Expected next availability *
                </label>
                <input
                  style={{ width: "65%" }}
                  className={classes.categoryInput}
                  type="text"
                  value={arrivalDate}
                  name="arrivalDate"
                  id="arrivalDate"
                  placeholder="Eg: 'Coming On 1st October 2023'"
                  onChange={handleDateChange}
                />
              </div>
            )}

            <div className={classes.formInput}>
              <label htmlFor="coverImg">Upload Book Cover Image *</label>
              <input
                type="file"
                accept="image/*"
                name="coverImg"
                id="coverImg"
                onChange={handleCoverChange}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="previewPdf">Upload Book Preview PDF *</label>
              <input
                type="file"
                accept="application/pdf"
                name="previewPdf"
                id="previewPdf"
                onChange={handlePreviewChange}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="completePdf">Upload Book Complete PDF *</label>
              <input
                type="file"
                accept="application/pdf"
                name="completePdf"
                id="completePdf"
                onChange={handleCompletePdfChange}
                required
              />
            </div>

            <div className={classes.formInput}>
              <label htmlFor="noteToReader">Upload Note to Reader PDF*</label>
              <input
                type="file"
                accept="application/pdf"
                name="noteToReader"
                id="noteToReader"
                onChange={handleNoteChange}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="contentTable">Upload Content Table PDF*</label>
              <input
                type="file"
                accept="application/pdf"
                name="contentTable"
                id="contentTable"
                onChange={handleContentTableChange}
                required
              />
            </div>

            <div className={classes.indexDiv}>
              <label htmlFor="index">Upload Index *</label>
              <CKEditor
                name="index"
                editor={ClassicEditor}
                data="<p>Enter Index here...</p>"
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setIndex(data);
                  console.log({ event, editor, data });
                }}
              />
            </div>
            <div className={classes.formControl}>
              <button onClick={formCloseHandler}> Close</button>
              <button type="submit"> Submit</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default AddCategory;
