import React, { useState, useEffect } from "react";
import { BASE_URL } from "../apiConfig";

import classes from "./Sidebar.module.css";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";

import { Link } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();
  const [currBtn, setCurrBtn] = useState("1");

  const routePath = useLocation().pathname;
  useEffect(() => {
    if (routePath === "/myprofile") {
      setCurrBtn("1");
    } else if (routePath === "/myprofile/edit") {
      setCurrBtn("2");
    } else if (routePath === "/myprofile/password") {
      setCurrBtn("3");
    }
  }, [routePath]);

  const logoutHandler = () => {
    // Send a request to the server to log out the user
    axios
      .post(`${BASE_URL}/user/logout`, null, {
        withCredentials: true,
      })
      .then((response) => {
        // Check if the logout was successful
        if (response.status === 200) {
          // Redirect the user to the login page or perform any other action
          // window.location.href = "/welcome";
          navigate("/welcome"); //CHANGED THIS
        } else {
          // Handle logout error
          console.error("Logout failed.");
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error during logout:", error);
      });
  };

  return (
    <div className={classes.sidebar}>
      <div className={classes["sidebar-content-container"]}>
        <Link style={{ textDecoration: "none" }} to="/myprofile">
          <button
            className={
              currBtn === "1"
                ? classes["sidebar-content-active"]
                : classes["sidebar-content"]
            }
            id="1"
          >
            <HomeIcon className={classes.dashboardIcon} />
            <h3 className={classes.text}>Account</h3>
            <ChevronRightIcon className={classes.rightIcon} />
          </button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/myprofile/edit">
          <button
            className={
              currBtn === "2"
                ? classes["sidebar-content-active"]
                : classes["sidebar-content"]
            }
            id="2"
          >
            <EditIcon className={classes.dashboardIcon} />
            <h3 className={classes.text}>Details</h3>
            <ChevronRightIcon className={classes.rightIcon} />
          </button>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/myprofile/password">
          <button
            className={
              currBtn === "3"
                ? classes["sidebar-content-active"]
                : classes["sidebar-content"]
            }
            id="3"
          >
            <VpnKeyIcon className={classes.dashboardIcon} />
            <h3 className={classes.text}>Password</h3>
            <ChevronRightIcon className={classes.rightIcon} />
          </button>
        </Link>

        <Link style={{ textDecoration: "none" }} onClick={logoutHandler}>
          <button
            className={
              currBtn === "4"
                ? classes["sidebar-content-active"]
                : classes["sidebar-content"]
            }
            id="4"
          >
            <LogoutIcon className={classes.dashboardIcon} />
            <h3 className={classes.text}>Logout</h3>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
