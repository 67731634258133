import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import ContactUsModal from "./ContactUsModal";

function Footer() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <div>
            <Link to="/">
              <p>Kashi</p>
            </Link>
          </div>
          <div>
            {/* <Link to="/admin/login"> */}
            <p>Copyrights Reserved</p>
            {/* </Link> */}
          </div>
          <div>
            <Link onClick={handleOpen}>
              <p>Contact us</p>
            </Link>
          </div>
        </div>
      </div>

      {/* CONTACT US MODAL */}
      <ContactUsModal open={open} onClose={handleClose} />
    </>
  );
}

export default Footer;
