import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import classes from "./AddCustomer.module.css";
import CloseIcon from "@mui/icons-material/Close";

function AddCustomer(props) {
  const [userDetails, setUserDetails] = useState({
    fName: "",
    lName: "",
    email: "",
    mobile: "",
    city: "",
    country: "",
    password: "",
  });

  const { fName, lName, email, mobile, city, country, password } = userDetails;

  function inputChangeHandler(e) {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  }

  //FOR ADMIN TO ADD A NEW CUSTOMER
  async function formSubmitHandler(event) {
    event.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/addCustomer`,
        {
          ...userDetails,
        },
        { withCredentials: true }
      );
      // toast.success("User added!");

      const { success, message } = data;

      if (success) {
        // setTimeout(() => {
        //   props.onClose();
        // }, 200);
        toast.success("User added!");

        // props.onAdd(userDetails);
      } else {
        toast.error("Error adding user!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error adding user!");
    }
    setUserDetails({
      ...userDetails,
      fName: "",
      lName: "",
      email: "",
      mobile: "",
      city: "",
      country: "",
      password: "",
    });
  }

  function formCloseHandler() {
    props.onClose();
  }

  return (
    <>
      <div className={classes.addCustomerForm}>
        <div className={classes["addCustomerForm-Header"]}>
          <h3>Add Customer</h3>
          <CloseIcon className={classes.closeIcon} onClick={formCloseHandler} />
        </div>
        <hr />
        <div className={classes["addCustomerForm-content"]}>
          <h3>Customer's Details</h3>

          <form onSubmit={formSubmitHandler}>
            <div className={classes.formInput}>
              <label htmlFor="fName">First Name *</label>
              <input
                className={classes.customerInput}
                id="fName"
                type="text"
                name="fName"
                value={fName}
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="lName">Last Name *</label>
              <input
                className={classes.customerInput}
                type="text"
                value={lName}
                name="lName"
                id="lName"
                required
                onChange={inputChangeHandler}
              />
            </div>

            <div className={classes.formInput}>
              <label htmlFor="email">Email *</label>
              <input
                className={`${classes.customerInput} ${classes.priceInput}`}
                type="email"
                value={email}
                name="email"
                id="email"
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="mobile">Mobile Number *</label>
              <input
                className={`${classes.customerInput} ${classes.priceInput}`}
                type="tel"
                value={mobile}
                name="mobile"
                id="mobile"
                pattern="[0-9]{10}"
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="city">City *</label>
              <input
                className={`${classes.customerInput} ${classes.priceInput}`}
                type="text"
                value={city}
                name="city"
                id="city"
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="country">Country *</label>
              <input
                className={`${classes.customerInput} ${classes.priceInput}`}
                type="text"
                value={country}
                name="country"
                id="country"
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="password">Set Password *</label>
              <input
                className={`${classes.customerInput} ${classes.priceInput}`}
                type="password"
                value={password}
                name="password"
                id="password"
                required
                onChange={inputChangeHandler}
              />
            </div>

            <div className={classes.formControl}>
              <button onClick={formCloseHandler}> Cancel</button>
              <button type="submit"> Create Account</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default AddCustomer;
