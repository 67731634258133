import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../apiConfig";

import axios from "axios";
import classes from "./Sidebar.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";

import { Link } from "react-router-dom";

function Sidebar(props) {
  const navigate = useNavigate();
  const [currBtn, setCurrBtn] = useState("1");

  // FOR ADMIN LOGOUT
  const logoutHandler = () => {
    // Send a request to the server to log out the user
    axios
      .post(`${BASE_URL}/admin/logout`, null, {
        withCredentials: true,
      })
      .then((response) => {
        // Check if the logout was successful
        if (response.status === 200) {
          // Redirect the admin to the login page or perform any other action
          // window.location.href = "/admin/login";
          navigate("/admin/login"); //CHANGED THIS
        } else {
          // Handle logout error
          console.error("Logout failed.");
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error during logout:", error);
      });
  };

  const routePath = useLocation().pathname;
  useEffect(() => {
    if (routePath === "/admin/dashboard") {
      setCurrBtn("1");
    } else if (routePath === "/admin/homepage") {
      setCurrBtn("2");
    } else if (routePath === "/admin/category") {
      setCurrBtn("3");
    } else if (routePath === "/admin/customer") {
      setCurrBtn("4");
    } else if (routePath === "/admin/password") {
      setCurrBtn("5");
    }
  }, [routePath]);

  return (
    <>
      <div className={classes.sidebar}>
        <div className={classes["sidebar-content-container"]}>
          <Link style={{ textDecoration: "none" }} to="/admin/dashboard">
            <button
              className={
                currBtn === "1"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="1"
            >
              <DashboardIcon className={classes.dashboardIcon} />
              <h3>Dashboard</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/homepage">
            <button
              className={
                currBtn === "2"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="2"
            >
              <HomeIcon className={classes.dashboardIcon} />
              <h3>Homepage</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/category">
            <button
              className={
                currBtn === "3"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="3"
            >
              <LibraryBooksIcon className={classes.dashboardIcon} />
              <h3>Books</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/customer">
            <button
              className={
                currBtn === "4"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="4"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Customers</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/password">
            <button
              className={
                currBtn === "5"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="5"
            >
              <VpnKeyIcon className={classes.dashboardIcon} />
              <h3>Password</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} onClick={logoutHandler}>
            <button
              onClick={logoutHandler}
              className={
                currBtn === "6"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="6"
            >
              <LogoutIcon className={classes.dashboardIcon} />
              <h3>Log out</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
