import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import classes from "./Customer.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCustomer from "../components/AddCustomer";
import CustomerBooksPopUp from "../components/CustomerBooksPopUp";

function Customer() {
  const [myUsers, setMyUsers] = useState([]);
  const [hasUsers, setHasUsers] = useState(true);
  const [addUserForm, setAddUserForm] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const [viewUserBooks, setViewUserBooks] = useState(false);

  // to GET all users and display in table
  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/users`, { withCredentials: true })
      .then((res) => {
        // setAvlBooks(res.data);
        setMyUsers(res.data);
        setHasUsers(true);
        console.log(res.data);
      })
      .catch((err) => {
        setHasUsers(false);
        console.log("Error fetching users:" + err);
      });
  }, []);

  // on adding a new user through add cutomer component
  function addUserHandler(newUser) {
    setMyUsers((prevUsers) => [...prevUsers, newUser]);
  }

  // TO DELETE A SPECIFIC USER
  function deleteUserHandler(userId) {
    axios
      .post(`${BASE_URL}/admin/users/${userId}`, null, { //remember to pass null as body when using "post" for delete request
        withCredentials: true,
      })
      .then(() => {
        setMyUsers((prevUsers) => {
          return prevUsers.filter((user) => {
            return user._id !== userId;
          });
        });
        toast.success("User deleted!");
      })
      .catch((error) => {
        toast.error("Error deleting user!");
        console.log("Error deleting user:", error);
      });
  }

  function formOpenHandler() {
    setAddUserForm(true);
  }

  function formCloseHandler() {
    setAddUserForm(false);
  }

  function openPopUpHandler(userId) {
    setViewUserBooks(true);
    setActiveUserId(userId);
  }

  function closePopUpHandler() {
    setViewUserBooks(false);
  }

  const usersTableRows = myUsers.map((user) => {
    return (
      <tr key={user._id}>
        <td>{`${user.fName} ${user.lName}`}</td>
        <td>{user.mobile}</td>
        <td>{user.email}</td>
        <td>{user.city}</td>
        <td>{user.country}</td>
        <td
          className={classes.viewCustomer}
          onClick={() => openPopUpHandler(user._id)}
        >
          View
        </td>
        <td style={{ textAlign: "center" }}>
          <DeleteIcon
            fontSize="small"
            onClick={() => deleteUserHandler(user._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.customer}>
        <Header />
        <div className={classes.addCustomer}>
          <h3>Our Customers</h3>
          <button className={classes.addDiv} onClick={formOpenHandler}>
            <AddIcon className={classes.addIcon} />
            <h4>Add</h4>
          </button>
        </div>
        <hr />

        {addUserForm && (
          <AddCustomer onAdd={addUserHandler} onClose={formCloseHandler} />
        )}

        <table className={classes.table}>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>City</th>
              <th>Country</th>
              <th>Books Purchased</th>
              <th>Action</th>
            </tr>
            {!hasUsers ? <p>No Users found!</p> : usersTableRows}
          </tbody>
        </table>

        {/* BOOKS PURCHASED BY CUSTOMER POP-UP */}
        {viewUserBooks && (
          <CustomerBooksPopUp
            userId={activeUserId}
            onClose={closePopUpHandler}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Customer;
