import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import classes from "./AdminLogin.module.css";

function AdminLogin() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
  });

  // console.log(document.cookie);

  const { username, password } = inputValue;

  function handleOnChange(e) {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  // FOR ADMIN LOGIN
  async function submitHandler(e) {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/login`,
        { ...inputValue },
        { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 1200);
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setInputValue({
      ...inputValue,
      username: "",
      password: "",
    });
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.formContainer}>
        <form onSubmit={submitHandler}>
          <h3>Admin Log In</h3>
          <div className="form-group">
            <input
              onChange={handleOnChange}
              value={username}
              type="text"
              className="form-control"
              name="username"
              placeholder="Enter username"
            />
          </div>
          <div className="form-group">
            <input
              onChange={handleOnChange}
              value={password}
              type="password"
              className="form-control"
              name="password"
              placeholder="Enter password"
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Log In
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AdminLogin;
