import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import classes from "./HomepageFilesUpload.module.css";
import { useState } from "react";

function HomepageFilesUpload() {
  const [mainPreviewPdf, setMainPreviewPdf] = useState(null);
  const [authorPdf, setAuthorPdf] = useState(null);
  const [whyPdf, setWhyPdf] = useState(null);
  // const [message, setMessage] = useState("");

  function handleMainPreviewPdfChange(e) {
    setMainPreviewPdf(e.target.files[0]);
  }

  function handleAuthorPdfChange(e) {
    setAuthorPdf(e.target.files[0]);
  }

  function handleWhyPdfChange(e) {
    setWhyPdf(e.target.files[0]);
  }

  async function uploadFilesHandler(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("mainPreviewPdf", mainPreviewPdf);
    formData.append("authorPdf", authorPdf);
    formData.append("whyPdf", whyPdf);

    try {
      await axios.post(`${BASE_URL}/admin/homepage/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      toast.success("File(s) uploaded successfully!");
      // setMessage("File(s) uploaded successfully!");
      console.log("Files uploaded successfully!");
    } catch (error) {
      toast.error("Error uploading files!");
      // alert("Error uploading files!");
      console.error("Error uploading files!");
    }

    // console.log("Uploaded");
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar />

      <div className={classes.password}>
        <Header />
        <div className={classes["password-container"]}>
          <h2>
            <strong>Upload Pdfs</strong>
          </h2>
          <form onSubmit={uploadFilesHandler}>
            <div className={classes.formInput}>
              <label htmlFor="mainPreviewPdf">Main Preview PDF:</label>
              <input
                className={classes.categoryInput}
                type="file"
                accept="application/pdf"
                name="mainPreviewPdf"
                id="mainPreviewPdf"
                onChange={handleMainPreviewPdfChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="authorPdf">Author PDF:</label>
              <input
                className={classes.categoryInput}
                type="file"
                accept="application/pdf"
                name="authorPdf"
                id="authorPdf"
                onChange={handleAuthorPdfChange}
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="whyPdf">Why a new book on Physics? PDF:</label>
              <input
                className={classes.categoryInput}
                type="file"
                accept="application/pdf"
                name="whyPdf"
                id="whyPdf"
                onChange={handleWhyPdfChange}
              />
            </div>

            {/* <p style={{ color: "grey" }}>{message}</p> */}

            <div className={classes.passwordBottomBar}>
              <button type="submit">Upload Files</button>
            </div>
          </form>
          {/* {message && <p style={{ color: "grey" }}>{message}</p>} */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default HomepageFilesUpload;
