import React from "react";
import Header from "./Header";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";

function Dashboard(props) {
  console.log(props.books);
  console.log(props.myUsers);

  return (
    <>
      <div className={classes.dashboard}>
        <Header />

        <div className={classes.dashboardCardContainer}>
          <Link style={{ textDecoration: "none" }} to="/admin/category">
            <div className={`${classes.dashboardCard} ${classes.first}`}>
              <h3>Categories</h3>
              <p>Total Categories</p>
              <h1>{props.books.length}</h1>
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/customer">
            <div className={`${classes.dashboardCard} ${classes.second}`}>
              <h3>Customers</h3>
              <p>Total Customers</p>
              <h1>{props.myUsers.length}</h1>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
